<template>
  <div class="text-dark-blue login">
    <router-link to="/" class="text-dark-blue">
      <h1 class="mb-5 text-dark-blue">{{ $t("documentManagementSystem") }}</h1>
    </router-link>
    <div class="login-form">
      <Alert :alert="alert" />
      <b-form class="form-style" @submit.prevent="sendEmailLink">
        <b-form-group class="password-icon">
          <label for="email">{{ $t("emailAddress") }}</label>
          <b-form-input
            type="email"
            inputmode="email"
            id="email"
            v-model="$v.email.$model"
            :state="!$v.email.$error"
          />
          <b-form-invalid-feedback v-if="!$v.email.required">{{
            $t("validateMessages.pleaseEmail")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.email.email">{{
            $t("validateMessages.pleaseEmailFormat")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <button class="blue-button w-100 mt-3">
          <span v-if="!loading">{{ $t("send") }}</span>
          <b-spinner variant="light" v-else></b-spinner>
        </button>
      </b-form>
    </div>
  </div>
</template>

<script>
import Input from "../base/formElements/Input.vue";
import { validationMixin } from "vuelidate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Alert from "../base/alert/Alert.vue";
import { sendEmailPasswordLink } from "../../services/modules/User";

const { required, email } = require("vuelidate/lib/validators");

export default {
  components: {
    Input,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      email: "",
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      loading: false,
    };
  },
  methods: {
    async sendEmailLink() {
      this.$v.$touch();
      this.$v.email.$touch();
      if (!this.$v.email.$anyError) {
        this.loading = true;
        const response = await sendEmailPasswordLink(
          this.email,
          this.$i18n.locale
        );

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "sendEmailText";
        } else if (response.message === "NOT_FOUND") {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "matchEmail";
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "notSendEmailText";
        }
        this.loading = false;
        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>

<style lang="scss">
.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/image-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .login-form {
    width: 100%;
    max-width: 500px !important;
    padding: 0 20px;
  }

  .forgot-password-route {
    p {
      text-decoration: underline;
    }
  }

  .password-icon {
    position: relative;

    .input-icon {
      position: absolute;
      top: 60%;
      right: 1.2rem;
      color: #01295f;
    }
  }
}
</style>