<template>
  <ForgotPassword />
</template>

<script>
import ForgotPassword from "../components/forgotPassword/ForgotPassword.vue";

export default {
  components: { ForgotPassword },
};
</script>

<style>
</style>