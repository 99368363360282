<template>
  <div class="form-input">
    <div class="d-flex justify-content-between align-items-center">
      <label :for="id" class="text-dark-blue font-weight-bold">{{
        text
      }}</label>
      <p v-if="optional" class="optional text-muted">{{ $t("optional") }}</p>
    </div>
    <b-form-input
      :type="type"
      :id="id"
      :placeholder="placeholder"
      @input="(e) => $emit('input', e)"
      :value="value"
      :state="state"
      :disabled="disabled"
    ></b-form-input>
    <font-awesome-icon :icon="icon" v-if="showImage" class="input-icon" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faEye);

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    optional: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faEye,
    };
  },
};
</script>

<style lang="scss">
.form-input {
  margin-bottom: 20px;
  position: relative;

  .optional {
    padding: 0px 10px 0px 0px;
    font-size: 14px;
  }
  input {
    border: 2px solid #01295f;
    border-radius: 20px;
    padding: 1.4rem 1rem;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(1, 41, 95, 0.25);
      border: 2px solid #01295f;
    }
  }
  .input-icon {
    position: absolute;
    top: 60%;
    right: 1.4rem;
    color: #01295f;
  }
}
</style>